import React, { useState, useEffect } from "react";
import { Tabs, Tab, styled, useMediaQuery, useTheme, IconButton, Menu, MenuItem, Box, AppBar, Toolbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth';
import { app } from "../firebase";
import MenuIcon from '@mui/icons-material/Menu';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';


const TabLabel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});


const StyledTab = styled(Tab)(({ theme }) => ({
  color: 'black',
  '&:hover': {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  '&.Mui-selected': {
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  },
  transition: 'box-shadow 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    padding: '6px 12px',
    fontSize: '0.8rem',
  },
}));

function NavigationTabs({ user }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(false);
  const auth = getAuth(app);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (['/login', '/signout', '/about'].includes(location.pathname)) {
      setValue(false);
    } else {
      setValue(location.pathname);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User signed out successfully");
      sessionStorage.removeItem('userInfo');
      sessionStorage.removeItem('firebaseToken');
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
    }
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const storedUser = sessionStorage.getItem('userInfo');
  const _parsedUser = JSON.parse(storedUser);
  let _user = null;
  if (_parsedUser && user && _parsedUser.userId === user.uid) {
    _user = _parsedUser.userId;
  }

  const headerItems = _user
    ? [
        { label: "Home", value: "/" },
        { label: "Employer Search", value: "/All" },
        { label: "WatchList", value: "/watchlist" }
      ]
    : [{ label: "About", value: "/about" }];

  const menuItems = _user
    ? [
        { label: "Profile", value: "/profile" },
        { label: "Sign Out", value: "/signout", onClick: handleSignOut },
        { label: "About", value: "/about" }
      ]
    : [{ label: "Login", value: "/login" }];

  if (isMobile) {
    return (
      <AppBar position="static" color="default">
        <Toolbar>
          <Tabs 
            value={value} 
            onChange={handleChange} 
            textColor="inherit" 
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ flexGrow: 1 }}
          >
 {headerItems.map((item) => (
  <StyledTab
    key={item.value}
    label={
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {item.label}
        {item.label === "WatchList" && <BookmarkAddedIcon fontSize="small" />}
      </div>
    }
    value={item.value}
    component={Link}
    to={item.value}
  />
))}
          </Tabs>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {menuItems.map((item) => (
              <MenuItem
                key={item.value}
                onClick={() => {
                  if (item.onClick) {
                    item.onClick();
                  } else {
                    navigate(item.value);
                  }
                  handleMenuClose();
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }

  // Desktop layout remains unchanged
  return (
    <Tabs 
      value={value} 
      onChange={handleChange} 
      textColor="inherit" 
      indicatorColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      sx={{ 
        backgroundColor: 'white',
        '& .MuiTabs-indicator': {
          backgroundColor: 'black',
        },
      }}
    >
      {[...headerItems, ...menuItems].map((item) => (
          <StyledTab
          key={item.value}
          label={
            <TabLabel>
              {item.label}
              {item.label === "WatchList" && <BookmarkAddedIcon fontSize="small" />}
            </TabLabel>
          }
          value={item.value}
          component={Link}
          to={item.value}
          onClick={item.onClick}
        />
      ))}
    </Tabs>
  );
}

export default NavigationTabs;